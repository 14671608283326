import React from "react";
import { EarnIcon, SuperIcon, MerchantIcon, UserIcon } from "../../Common/SvgIcons/SvgIcons";
import "./ChooseTriskel.scss";
import { Row, Col } from "antd";
function ChooseTriskel() {
  const cardData = [
    {
      title: "Super Admin Panel",
      description: ["Monitor merchant activity, KYC/KYB processes, and revenue.", " Enable or disable supported cryptocurrencies."],
      image: <SuperIcon />,
    },
    {
      title: "Merchant Panel",
      description: ["Dashboard for transaction insights, crypto balances, and revenue tracking.", "Integration SDK activation for businesses."],
      image: <MerchantIcon />,
    },
    {
      title: "User-Friendly Checkout",
      description: ["QR code payments for crypto transactions."],
      image: <UserIcon />,
    },
    {
      title: "Earn Commissions",
      description: ["Triskel Pay is a strong revenue source for merchants."],
      image: <EarnIcon />,
    },
  ];

  return (
    <div className="chooseMerchant">
      <div className="chooseTriskel container">
        <p className="chooseTriskel_featurepage">Features Page</p>
        <h3 className="chooseTriskel_head">Why Choose Triskel Merchant Payment Gateway?</h3>
        <div className="chooseCards">
          <Row gutter={[20, 0]} justify="center">
            {cardData.map((card, index) => (
              <Col sm={24} md={12} lg={12} xl={12} key={index}>
                <div className="chooseCards_inner">
                  <div className="chooseCards_icons">{card.image}</div>
                  <div className="chooseCards_inner_right">
                    <h4>{card.title}</h4>
                    <ul>
                      {card.description.map((desc, descIndex) => (
                        <li key={descIndex}>{desc}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ChooseTriskel;
