import React from "react";
import GlobalAccessibility from "../../assets/GlobalAccessibility.png";
import LowFees from "../../assets/LowFees.png";
import MultiCurrencySupport from "../../assets/MultiCurrencySupport.png";
import SecureTransactions from "../../assets/SecureTransactions.png";
import RevolutionizingPayments from "../../assets/RevolutionizingPayments.png";
import UserCheckout from "../../assets/UserCheckout.png";
import CryptoPayment from "../../assets/CryptoPayment.png";
import SeamlessSettlement from "../../assets/SeamlessSettlement.png";
import paymentsMadeline from "../../assets/paymentsMadeline.png";
import { Link } from "react-router-dom";
import { RightArrow2 } from "../../Common/SvgIcons/SvgIcons";
function QuickBenefits() {
  const benefits = [
    {
      imgSrc: GlobalAccessibility,
      title: "Global Accessibility",
      description: "Accept payments from anywhere in the world.",
    },
    {
      imgSrc: LowFees,
      title: "Low Fees",
      description: "Reduce costs compared to traditional payment gateways.",
    },
    {
      imgSrc: MultiCurrencySupport,
      title: "Multi-Currency Support",
      description: "Crypto option.",
    },
    {
      imgSrc: SecureTransactions,
      title: "Secure Transactions",
      description: "Powered by blockchain and advanced encryption.",
    },
  ];
  const cardData = [
    {
      id: 1,
      imgSrc: UserCheckout,
      title: "User Checkout",
      description: "Customers select Triskel Pay as their payment method on the merchant’s website.",
      number: "01",
    },
    {
      id: 2,
      imgSrc: CryptoPayment,
      title: "Crypto Payment",
      description: "Users can pay with crypto wallets using QR codes or wallet addresses.",
      number: "02",
    },
    {
      id: 3,
      imgSrc: SeamlessSettlement,
      title: "Seamless Settlement",
      description: "Payments are processed via Triskel’s backend and transferred to the merchant securely.",
      number: "03",
    },
  ];
  return (
    <>
      <div className="quickBenefits">
        <div className="container">
          <div className="quickBenefits_top">
            <h2>Quick Benefits</h2>
            <div className="quickBenefits_top_quickCards">
              {benefits.map((benefit, index) => (
                <div className="quickCard" key={index}>
                  <img src={benefit.imgSrc} alt="img" />
                  <div>
                    <h4>{benefit.title}</h4>
                    <p>{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="revolutionizingPayments">
              <h5>About</h5>
              <div className="revolutionizingPayments_text">
                <h2>Revolutionizing Payments for Businesses and Crypto Enthusiasts</h2>
                <p>
                  Triskel Pay bridges the gap between cryptocurrencies and everyday transactions, enabling merchants to integrate crypto payments into their business. Whether you're an e-commerce store, a retail outlet, or a digital service provider, Triskel ensures fast, secure, and borderless
                  transactions.
                </p>
              </div>
              <img src={RevolutionizingPayments} alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="paymentsMade">
        <div className="container paymentsMade_content">
          <div className="paymentsMade_left">
            <h5>How It Works</h5>
            <div className="paymentsMade_left_cryptoTest">
              <h2>Crypto Payments Made Simple</h2>
              <p>Triskel Pay bridges the gap between cryptocurrencies and everyday transactions, enabling merchants to integrate crypto payments into their business.</p>
            </div>
            <Link>
              <u> Learn More About the Payment Process</u>
              <span>
                <RightArrow2 />
              </span>
            </Link>
          </div>
          <div className="paymentsMade_right">
            <div className="cardsLine">
              <img src={paymentsMadeline} alt="" />
            </div>
            <div className="cardsContainer">
              {cardData.map((card) => (
                <div className="paymentsMadeCard" key={card.id}>
                  <img src={card.imgSrc} alt="img" />
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                  <h4>{card.number}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}

export default QuickBenefits;
