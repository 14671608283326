import React from "react";
import "./ArchitectingNext.scss";
import TriskelPay from "./TriskelPay";
import QuickBenefits from "./QuickBenefits";
import ChooseTriskel from "./ChooseTriskel";
import PricingPage from "./PricingPage";
import DashboardInsight from "./DashboardInsight";
import Faq from "./Faq.jsx";
import GetIntouch from "./GetIntouch";
function ArchitectingNext() {
  return (
    <div className="architecting-main ">
      <TriskelPay />
      <QuickBenefits />
      <ChooseTriskel />
      <PricingPage />
      <DashboardInsight />
      <Faq />
      <GetIntouch />
    </div>
  );
}

export default ArchitectingNext;
