import React from "react";
import "./PricingPage.scss";
import cryptpay1 from "../../assets/cryptopay1.png";
import cryptpay2 from "../../assets/cryptopay2.png";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
function PricingPage() {
  const cryptoPaymentData = [
    {
      imgSrc: cryptpay1,
      title: "Crypto Payments Only",
      description: "Estimated Time: 6–8 weeks\nCost: $52,000",
    },
    {
      imgSrc: cryptpay2,
      title: "Crypto Payments",
      description: "Estimated Time: 10–12 weeks\nCost: $79,000",
    },
  ];

  return (
    <div className="pricingPage commonSecbg">
      <div className="container">
        <h5>Pricing Page</h5>
        <h2>Flexible Plans Tailored to Your Busines</h2>
        <div className="cryptoPayment">
          {cryptoPaymentData.map((item, index) => (
            <div className="cryptoPayment_card" key={index}>
              <img src={item.imgSrc} alt={item.title} />
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
        <ButtonCustom greenbutton label="Get a Quote Now" htmlType="Get a Quote Now" btnBorder={true} />
      </div>
    </div>
  );
}

export default PricingPage;
