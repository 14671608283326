import React from "react";
import "./ArchitectingNext.scss";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import TriskelPayRight from "../../assets/TriskelPay-right.png";
import { useNavigate } from "react-router-dom";
function TriskelPay() {
  const navigate = useNavigate();
  const registermerchant = () => {
    navigate("/registerMerchant");
  };

  return (
    <div className="triskelPay ">
      <div className="triskelPay_content container">
        <div className="triskelPay_left">
          <h5>Triskel Pay</h5>
          <h1>
            Architecting Next- <br />
            Generation Payment <br /> Infrastructure
          </h1>
          <p>Transform your payment ecosystem with seamless crypto integration</p>
          <div className="triskelPay_left_btn">
            <ButtonCustom onClick={registermerchant} greenbutton label="Get Started" htmlType="Get Started" btnBorder={true} />
          </div>
        </div>
        <div className="triskelPay_right">
          <img src={TriskelPayRight} alt="TriskelPayRight" />
        </div>
      </div>
    </div>
  );
}

export default TriskelPay;
