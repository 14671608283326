import React from "react";
import "./ArchitectingNext.scss";
import dashboardinsight from "../../assets/dashboardinsight.png";
function DashboardInsight() {
  return (
    <div className="commonSecbg">
      <div className="dashboardinsight container">
        <div className="dashboardinsight_left">
          <h5>Dashboard Insights Page</h5>
          <h2>Monitor Your Transactions with Precision</h2>
          <p className="dashboardinsight_left_text">Both merchants and the super admin have access to advanced dashboards with insights on</p>
          <p className="dashboardinsight_left_realtext">
            Real-time transaction statistics.
            <br />
            Asset-wise logs for payments.
            <br />
            Revenue breakdown in crypto.
          </p>
          <div className="dashboardinsight_left_listing"></div>
        </div>
        <div className="dashboardinsight_right">
          <img src={dashboardinsight} alt="img" />
        </div>
      </div>
    </div>
  );
}

export default DashboardInsight;
