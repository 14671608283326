import React from "react";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import getinpay from "../../assets/getinpay.png";
import "./GetInTouch.scss";
import { useForm, Controller } from "react-hook-form";
import InputCustom from "../../Common/InputCustom/InputCustom.jsx";
function GetIntouch() {
  return (
    <div className="commonSecbg">
      <div className="getInTouch container">
        <div className="getInTouch_inner">
          <h2>Get in Touch with Triskel Pay</h2>
          <div className="getInTouch_inner_triskelPay">
            <img src={getinpay} alt="imgs" />
            <div className="getInTouch_inner_right">
              <div className="getInTouchInputs">
                <div className="getInTouchInputs_inner">
                  <InputCustom regularInput label="Last Name" placeholder="Enter Your First Name" customClass="triskelpayInput" />
                  <InputCustom regularInput label="Last Name" placeholder="Enter Your First Name" customClass="triskelpayInput" />
                </div>
                <div className="getInTouchInputs_inner">
                  <InputCustom regularInput label="Last Name" placeholder="Enter Your First Name" customClass="triskelpayInput" />
                  <InputCustom regularInput label="Last Name" placeholder="Enter Your First Name" customClass="triskelpayInput" />
                </div>
                <div className="getInTouchInputs_inner">
                  <InputCustom regularInput label="Last Name" placeholder="Enter Your First Name" customClass="triskelpayInput" />
                </div>
              </div>
              <ButtonCustom greenbutton label="Send Message" htmlType="Send Message" btnBorder={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetIntouch;
