import React from "react";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
import "./KeyFeature.scss";
function KeyFeature() {
  const {
    snapusdt,
    snaptoken,
    snaptst,
    snaptut,
    snaponoff,
    snapreferal,
    snapover,
    snapsend,
    snapsto,
    snapcross,
  } = StoreImages;
  const items1 = [
    { imgSrc: snapusdt, text: "USDT/USDC Collateralized Crypto Visa Card" },
    { imgSrc: snaponoff, text: "On and Off-ramp Services" },
    { imgSrc: snaptut, text: "TUT (Triskel Utility Token)" },
    // & TST (Triskel Security Token)

    { imgSrc: snaptst, text: "TST (Triskel Security Token)" },
    { imgSrc: snaptoken, text: "Tokenizing Assets" },
  ];

  const items2 = [
    { imgSrc: snapsto, text: "STO platform - Primary/Secondary Marketplace" },
    { imgSrc: snapcross, text: "Cross Chain Swapping" },
    { imgSrc: snapsend, text: "Send/Receive Assets" },
    { imgSrc: snapover, text: "OTC (Over the counter Platform)" },
    { imgSrc: snapreferal, text: "Web3 Authentication" },
  ];

  return (
    <div className="keyFeature" id="feature">
      <div className="container">
        <p className="greentext">Key Features</p>
        <div className="keyFeature_top">
          <div className="keyFeature_top_left">
            <h2>
              Snapshot of
              <br /> Super Features
            </h2>
          </div>
          <div className="keyFeature_top_right">
            <p>
              A glimpse of the main drivers of our non-custodial wallet,
              supplemented by unyielding security standards and efficient asset
              management for a one-in-all DeFi solution.
            </p>
          </div>
        </div>
        <div className="keyFeature_cards">
          <div className="keyFeature_cards_inner">
            {items1.map((item, index) => (
              <div className="innerItems" key={index}>
                <span>
                  <img src={item.imgSrc} alt={item.text} />
                </span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className="keyFeature_cards_inner">
            {items2.map((item, index) => (
              <div className="innerItems" key={index}>
                <span>
                  <img src={item.imgSrc} alt={item.text} />
                </span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeyFeature;
