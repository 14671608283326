import React from "react";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
import "./Transactions.scss";
import { Link } from "react-router-dom";
import { GreenApp, GreenPlay } from "../../../../Common/SvgIcons/SvgIcons";
function Transactions() {
  const { mastercard } = StoreImages;
  const transactionsData = [
    {
      number: "01",
      title: "Ease of Use",
      description:
        "Convert and spend USDT without transferring to a bank account.",
    },
    {
      number: "02",
      title: "Security and Privacy",
      description:
        "The decentralized nature of blockchain and user-managed funds in non-custodial wallets bolster privacy and mitigate fraud risks.",
    },
    {
      number: "03",
      title: "Global Acceptance",
      description:
        "Crypto Visa Card's network empowers transactions and payments worldwide, offering universal accessibility for online and offline use.",
    },
  ];
  return (
    <div className="simplifiedTransaction container">
      {/* <h2>Simplified Transactions with USDT-Backed Crypto Visa Card </h2> */}
      <h2>
        Simplified Transactions with USDT/USDC Polygon Backed Crypto Visa Cards
      </h2>
      <div className="transactions">
        <div className="transactions_left">
          <img src={mastercard} alt="mastercard" />
        </div>
        <div>
          <div className="transactions_right">
            {transactionsData.map((transaction, index) => (
              <div className="transactions_right_cards" key={index}>
                <span>{transaction.number}</span>
                <h4>{transaction.title}</h4>
                <p>{transaction.description}</p>
              </div>
            ))}
          </div>

          <div className="bannerbuttons">
            <Link to="https://play.google.com/store/search?q=triskel+wallet&c=apps">
              <GreenPlay />
            </Link>
            <Link to="https://apps.apple.com/in/app/triskel-wallet/id6449494626">
              <GreenApp />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
