import React from "react";
import { Collapse } from "antd";
import "../Home/HomeComponets/FrequentlyAsked/FrequentlyAsked.scss";

const { Panel } = Collapse;
function Faq() {
  return (
    <div className="commonSecbg">
      <div className="frequentlyAsked container">
        <h5>FAQs</h5>
        <h2>Answers to Your Questions</h2>
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel header={"1. What is Triskel Merchant Lorem Gateway?"} key="1">
            <p>The Triskel Franchise Program allows you to become a partner in our decentralized financial ecosystem, offering advanced DeFi services to your community while leveraging Triskel’s innovative platform and support network.</p>
          </Panel>
          <Panel header={"2.  Is Triskel a Lorem Ipsum"} key="2">
            <p>
              Absolutely! Triskel is a non-custodial cryptocurrency wallet to the core. You hold all the power over your private keys and assets—no middlemen, just direct, hands-on management. The entire setup amps up your security and hands you total control over your assets, perfectly capturing
              the bold spirit of decentralization.
            </p>
          </Panel>
          <Panel header={"3.  Why choose Lorem Ipsum simply dummy?"} key="3">
            <p>With Triskel Wallet, you get full control of your keys and assets. And its decentralized system, lets you dodge the risk of hacks associated with centralized storage. Triskel wallet operates in a completely transparent ecosystem, owing to the inherent nature of blockchain.</p>
          </Panel>
          <Panel header={"4. Can non-Triskel users make payments?"} key="4">
            <p>Non-custodial wallets generally let you stay anonymous, but KYC kicks in with exchanges, fiat services, etc. For instance, Triskel Wallet goes an extra mile, mandating KYC for OTC trades to keep things secure and trustworthy.</p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default Faq;
